import {
    Modal,
    Button,
    Typography,
    Collapse,
    Row,
    Col,
    Input,
    Divider,
    Form,
    Tabs,
    Cascader,
    Select,
    Card,
    Tag,
    DatePicker,
    Spin,
    TreeSelect,
    Space,
  } from 'antd';
  import React, { useState, useEffect, useContext } from 'react';
  import { addAppCustomNotification } from '../../components/NotificationBox.js';
  import { searchDataSetBreakdownPerIdWithOptionsAndPost, generateApiCallWithPost } from '../../services/kg_DataService';
  import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
  import CopyToClipboard from 'react-copy-to-clipboard';
  import dayjs from 'dayjs';
  import customParseFormat from 'dayjs/plugin/customParseFormat';
  import isBetween from 'dayjs/plugin/isBetween';
  import PolygonMap from '../../components/polygonmaps/polygonMap';
  import { KgWorkflowContext } from '../DashboardView.js';
  import { addItem } from '../../redux/product/actions.js';
  import { connect } from 'react-redux';
  import { v4 as uuidv4 } from 'uuid';
  import { useKeycloak } from '@react-keycloak/web';
  
  dayjs.extend(customParseFormat);
  dayjs.extend(isBetween);
  
  const { Text } = Typography;
  const { Panel } = Collapse;
  const { RangePicker } = DatePicker;
  
  function KgDetailedComponent(props) {
    const { datasetid, dataset, breakdown, persistentId, isopen, close, productsWF, sources } = props;
  
    const [form] = Form.useForm();
    const [dateform] = Form.useForm();
    const [dateformSentinel] = Form.useForm();
  
    const [pythonScriptWF, setPythonScriptWF] = useState(null);
    const [metaJSON, setMetaJson] = useState(null);
    const [generateScript, setGenerateScript] = useState(false);
    const [selectedPolygonDropdown, SetPolygonDropdown] = useState('');
  
    const [tabItems, SetTabItems] = useState([]);
    const [selectedMetaInfoFields, SetSelectedMetaInfoFields] = useState([]);
    const [selectedDownloadOption, SetSelectedDownloadOptions] = useState('');
    const [isReady, SetIsReady] = useState(false);
  
    const [geometry, setGeometry] = useState([]);
    const [sentinelOptions, SetSentinelOptions] = useState([]);
    const [selectedPolygon, setPolygon] = useState([]);
    const [removedPolygon, setRemovePolygon] = useState(false);
    const [selectedOption, setSelectedOption] = useState(selectedPolygonDropdown[0]);
    const [adamProductList, SetAdamProductList] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [adamDownloadOptions, SetAdamDownloadOptions] = useState([]);
    const [filteredAdamData, SetFilteredAdamData] = useState([]);
    const { count, tabId, pythonScript, metaJson, tabSet, reset, decrement, increment } = useContext(KgWorkflowContext);
    const { keycloak } = useKeycloak();
    const { Option } = Select;
  
    useEffect(() => {
        console.log(datasetid, dataset, breakdown, isopen, close, productsWF, persistentId, 'use', sources);
  
        if (productsWF) {
            // Step 1: Filter out items with empty or duplicate geometry arrays
            const uniqueProducts = productsWF.filter((item, index, self) => {
                // Filter out items with empty geometry arrays or duplicates
                return (
                    item.geometry && // Ensure item has a geometry array
                    item.geometry.length > 0 && // Ensure geometry array is not empty
                    self.findIndex((t) => t.geometry && JSON.stringify(t.geometry) === JSON.stringify(item.geometry)) === index
                    // Filter based on unique geometry arrays using JSON.stringify for comparison
                );
            });
  
            // Step 2: Map the filtered unique items to the desired format
            const mappedProducts = uniqueProducts.map((item) => ({
                value: JSON.stringify(item.geometry),
                label: item.title, // Assuming item.title is the label you want to assign
            }));
            mappedProducts.unshift({ value: '', label: 'Please select geometry' });
  
            SetPolygonDropdown(mappedProducts);
        }
    }, [selectedPolygon]);
  
    const getGeometry = (childData) => {
        setGeometry(childData);
    };
    const getRemovePolygon = (childData) => {
        setRemovePolygon(childData);
    };
  
    const onCloseModal = () => {
        close();
        SetTabItems([]);
        SetAdamDownloadOptions([]);
        SetAdamProductList([]);
        SetSelectedMetaInfoFields([]);
        SetSelectedDownloadOptions('');
        setSelectedProducts([]);
        SetIsReady(false);
        form.resetFields();
        dateform.resetFields();
        dateformSentinel.resetFields();
        setPolygon([]);
        setRemovePolygon(false);
    };
  
    const onCopyDefaultText = () => {
        //setDefaultValueCopied(true)
        addAppCustomNotification('Sub-section copied into clipboard!', 'SUCCESS', '');
    };
  
    const onchangeMetaDataOptions = (value) => {
        console.log('loginng Onchange meta info option');
        console.log(value);
        console.log(breakdown.cascader);
        SetSelectedMetaInfoFields(value);
    };
  
      const getDateRange = (dateform) => {
          const defaultRange = [dayjs('01/01/2024', 'DD/MM/YYYY'), dayjs('15/01/2024', 'DD/MM/YYYY')];
  
          // Get the date range from the form
          let selectedDates = dateform.getFieldValue('daterange');
  
          // If no value is selected, return the default range
          return selectedDates || defaultRange;
      };
    const onchangeDownloadOptions = (value) => {
        SetSelectedDownloadOptions(value);
    };
  
    const BuildJsonMetaInfo = (values) => {
        var opts = [];
  
        console.log("meta info fields", values)
  
        var grouped = values.reduce((acc, item) => {
            var index = item[0];
            if (!acc[index]) {
                acc[index] = [];
            }
            acc[index].push({
                key: item[1],
                value: item[2],
            });
            return acc;
        }, {});
  
        for (var i in grouped) {
            var arrElement = grouped[i];
            var opt = {};
  
            for (let j = 0; j < arrElement.length; j++) {
                var element = arrElement[j];
                opt[element.key] = arrElement.filter((e) => e.key === element.key).map((e) => e.value);
            }
  
            opts.push({
                product_id: i,
                obj: opt,
            });
        }
        return opts;
    };
  
    const onGeneratePythonScript = () => {
        console.log('generating scripts for other source');
        var all_meta_infos = [];
  
        SetTabItems([]);
        all_meta_infos = BuildJsonMetaInfo(selectedMetaInfoFields);
  
        console.log("all meta info", all_meta_infos)
  
        var prodIds = all_meta_infos.map((el) => el.product_id);
  
        let dates = getDateRange(dateform);
   
        let newBounds;
 
        if(sources.some(item => item.toLowerCase() === "silam")) {
            const bounds = geometry[0].map(([lat, lng]) => [lat, lng]);
            bounds.push([geometry[0][0][0], geometry[0][0][1]]); // Close the geometry loop
            newBounds = [JSON.stringify([bounds])];
        }
  
        for (let k = 0; k < all_meta_infos.length; k++) {
            var meta_info = all_meta_infos[k].obj;
  
            if(sources.some(item => item.toLowerCase() === "silam")) {
                  meta_info['Geometry'] =  newBounds
                  meta_info['Date'] = dates.map((date) => date.toJSON())
              }
  
            var bodyContent = {
                datasetId: datasetid,
                datasetPersistentId: persistentId,
                functionalOptions: {
                    DownloadOption: [selectedDownloadOption],
                },
                requestOptions: meta_info,
            };
            console.log(meta_info)
            console.log('body content', bodyContent);
  
            
  
            generateApiCallWithPost(keycloak.token, datasetid, JSON.stringify(bodyContent))
                .then((response) => {
                    var randomId = prodIds[k];
                    let info = meta_info;
                    setPythonScriptWF(response.payload);
                    setMetaJson(JSON.stringify(info, null, 2));
                    let tabComponent = {
                        key: randomId,
                        label: 'Product ' + randomId,
  
                        children: (
                            <>
                                <Row>
                                    <Col span={15}>
                                        <Divider>
                                            Python Script{' '}
                                            <CopyToClipboard text={response.payload} onCopy={onCopyDefaultText}>
                                                <CopyOutlined style={{ color: '#418cc0' }} />
                                            </CopyToClipboard>
                                        </Divider>
                                        <pre style={{ maxHeight: 400, overflowY: 'scroll' }}>{response.payload}</pre>
                                    </Col>
                                    <Col span={8} offset={1}>
                                        <Divider>
                                            Meta Info (JSON){' '}
                                            <CopyToClipboard text={JSON.stringify(info, null, 2)} onCopy={onCopyDefaultText}>
                                                <CopyOutlined style={{ color: '#418cc0' }} />
                                            </CopyToClipboard>
                                        </Divider>
                                        <pre style={{ maxHeight: 400, overflowY: 'scroll' }}>{JSON.stringify(info, null, 2)}</pre>
                                    </Col>
                                </Row>
                                <Row justify={'center'}>
                                    <Col span={24}></Col>
                                </Row>
                            </>
                        ),
                    };
  
                    SetTabItems((current) => [...current, tabComponent]);
                    setGenerateScript(true);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const BuildJsonMetaInfoForAdam = (values) => {
        var pIds = values;
        // return list;
        if (dataset.isfromadam || sources.some(item => item.toLowerCase() === "silam")) {
            console.log(values, 'kspm', filteredAdamData);
            return pIds.map((id) => {
                return filteredAdamData.find((p) => p.id.toString().toUpperCase() == id.toString().toUpperCase());
            });
        } else return filteredAdamData;
    };
  
    const onGenerateFromAdamPythonScript = () => {
        var all_meta_infos = [];
  
        SetTabItems([]);
  
        all_meta_infos = BuildJsonMetaInfoForAdam(selectedMetaInfoFields);
  
        const geometriesTemp = geometry;
  
        const extraInfo = {
            datasetId: datasetid,
            persistentId: persistentId,
            datasetName: dataset.title,
            description: dataset.description,
            variables: 'variables',
            fileformats: 'fileformats',
            geometry: geometriesTemp,
        };
        all_meta_infos.push({ extraInfo: extraInfo });
  
        const info = all_meta_infos.flat();
        console.log(JSON.stringify(info, null, 2))
        setMetaJson(JSON.stringify(info, null, 2));
        var bodyContent = {
            datasetId: datasetid,
            datasetPersistentId: persistentId,
            requestOptions: {
                ProductID: selectedMetaInfoFields,
                Compress: Array.of(selectedDownloadOption),
            }
        };
        generateApiCallWithPost(keycloak.token, datasetid, JSON.stringify(bodyContent))
            .then((response) => {
                console.log(response.payload);
                setPythonScriptWF(response.payload);
                setMetaJson(JSON.stringify(info, null, 2));
  
                let tabComponent = {
                    key: 0,
                    label: 'Product(s)',
                    children: (
                        <>
                            <Row>
                                <Col span={15}>
                                    <Divider>
                                        Python Script{' '}
                                        <CopyToClipboard text={response.payload} onCopy={onCopyDefaultText}>
                                            <CopyOutlined style={{ color: '#418cc0' }} />
                                        </CopyToClipboard>
                                    </Divider>
                                    <pre style={{ maxHeight: 400, overflowY: 'scroll' }}>{response.payload}</pre>
                                </Col>
                                <Col span={8} offset={1}>
                                    <Divider>
                                        Meta Info (JSON){' '}
                                        <CopyToClipboard text={JSON.stringify(info, null, 2)} onCopy={onCopyDefaultText}>
                                            <CopyOutlined style={{ color: '#418cc0' }} />
                                        </CopyToClipboard>
                                    </Divider>
                                    <pre style={{ maxHeight: 400, overflowY: 'scroll' }}>{JSON.stringify(info, null, 2)}</pre>
                                </Col>
                            </Row>
                        </>
                    ),
                };
  
                SetTabItems((current) => [...current, tabComponent]);
                setGenerateScript(true);
            })
            .catch((err) => {
                addAppCustomNotification('Dashboard KG', 'CRITICAL', 'Encounter an error!');
                console.log(err);
            });
    };
    const onGenerateFromSentinelPythonScript = () => {
        var all_meta_infos = [];
        SetTabItems([]);
        
        let dates = getDateRange(dateformSentinel);
        // Generate geometry bounds
        const bounds = geometry[0].map(([lat, lng]) => [lat, lng]);
        bounds.push([geometry[0][0][0], geometry[0][0][1]]); // Close the geometry loop
        const newBounds = [JSON.stringify([bounds])];
  
        var options = {
            id: datasetid,
            datasetPersistentId: persistentId,
            requestOptions: {
              Geometry: newBounds,
  
              Date: dates.map((date) => date.toJSON()),
            },
        };
  
  
        SetSentinelOptions(options)
  
        var all_meta_infos_temp = BuildJsonMetaInfoForAdam(selectedMetaInfoFields);
        all_meta_infos = [all_meta_infos_temp];
  
        const geometriesTemp = geometry;
  
        const extraInfo = {
            datasetId: datasetid,
            datasetName: dataset.title,
            description: dataset.description,
            variables: 'variables',
            fileformats: 'fileformats',
            geometry: geometriesTemp,
        };
        all_meta_infos.push({ extraInfo: extraInfo });
        const info = all_meta_infos.flat();
  
        setMetaJson(JSON.stringify(info, null, 2));
        generateApiCallWithPost(keycloak.token, datasetid, JSON.stringify(options))
            .then((response) => {
                console.log(response);
                setPythonScriptWF(response.payload);
                setMetaJson(JSON.stringify(info, null, 2));
  
                let tabComponent = {
                    key: 0,
                    label: 'Product(s)',
                    children: (
                        <>
                            <Row>
                                <Col span={15}>
                                    <Divider>
                                        Python Script{' '}
                                        <CopyToClipboard text={response.payload} onCopy={onCopyDefaultText}>
                                            <CopyOutlined style={{ color: '#418cc0' }} />
                                        </CopyToClipboard>
                                    </Divider>
                                    <pre style={{ maxHeight: 400, overflowY: 'scroll' }}>{response.payload}</pre>
                                </Col>
                                <Col span={8} offset={1}>
                                    <Divider>
                                        Meta Info (JSON){' '}
                                        <CopyToClipboard text={JSON.stringify(info, null, 2)} onCopy={onCopyDefaultText}>
                                            <CopyOutlined style={{ color: '#418cc0' }} />
                                        </CopyToClipboard>
                                    </Divider>
                                    <pre style={{ maxHeight: 400, overflowY: 'scroll' }}>{JSON.stringify(info, null, 2)}</pre>
                                </Col>
                            </Row>
                        </>
                    ),
                };
  
                SetTabItems((current) => [...current, tabComponent]);
                setGenerateScript(true);
            })
            .catch((err) => {
                addAppCustomNotification('Dashboard KG', 'CRITICAL', 'Encountered an error!');
                console.log(err);
            });
    };
  
    const onCreateWF = () => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        props.addItem({
            id: uuidv4(),
            quantity: 0,
            title: dataset.title,
            dateTime: new Date().toLocaleDateString('en-GB', options).replace(',', ''),
            metaJSON: metaJSON,
            pythonScript: pythonScriptWF,
            geometry: geometry,
        });
        onCloseModal();
        let kg = { tabId: ['WC'] };
        tabSet(kg);
    };
  
    const convertPolygonToBoundingBox = (polygon) => {
        let minX = polygon[0][0];
        let minY = polygon[0][1];
        let maxX = polygon[0][0];
        let maxY = polygon[0][1];
  
        polygon.forEach(([x, y]) => {
            if (x < minX) minX = x;
            if (y < minY) minY = y;
            if (x > maxX) maxX = x;
            if (y > maxY) maxY = y;
        });
  
        return [minX, minY, maxX, maxY];
    };
  
      
    const ValidateAdamFilter = () => {
        const today = dayjs(); // Get today's date
       
        let dates = getDateRange(dateform);
  
        const isInvalidEndDate = dates[1].isAfter(today);
  
        // Validate only the end date
        if (isInvalidEndDate) {
            const warningMessage = `Invalid Date range! End date should be on or before ${today.format('DD/MM/YYYY')}!`;
            addAppCustomNotification('Dashboard KG', 'WARNING', warningMessage);
            return; // Exit early if the date range is invalid
        }
  
        // Generate geometry bounds
        const bounds = geometry[0].map(([lat, lng]) => [lat, lng]);
        bounds.push([geometry[0][0][0], geometry[0][0][1]]); // Close the geometry loop
        const newBounds = [JSON.stringify([bounds])];
  
        // Prepare options
        const options = {
            id: datasetid,
            datasetPersistentId: persistentId,
            requestOptions: {
                Geometry: newBounds,
                Date: dates.map((date) => date.toJSON()),
            },
        };
  
        SetIsReady(true);
  
        // Fetch dataset
        searchDataSetBreakdownPerIdWithOptionsAndPost(keycloak.token, datasetid, JSON.stringify(options))
            .then((response) => {
                if (response.payload?.products?.length) {
                    const productIds = response.payload.products.map((p) => ({
                        label: p.id,
                        value: p.id,
                        title: p.id,
                    }));
  
                    const downloadOptions = [
                        { label: 'True', value: 'True' },
                        { label: 'False', value: 'False' },
                    ];
  
                    SetAdamProductList(productIds);
                    SetAdamDownloadOptions(downloadOptions);
                    SetFilteredAdamData(response.payload.products);
                } else {
                    const noDataMessage = response.payload
                        ? 'No dataset for this area! Please try with a different range of date or area!'
                        : 'No dataset for this area!';
                    addAppCustomNotification('Dashboard KG', 'WARNING', noDataMessage);
                }
                SetIsReady(false);
            })
            .catch((err) => {
                console.error(err);
                addAppCustomNotification('Dashboard KG', 'CRITICAL', 'Encountered an error!');
                SetIsReady(false);
            });
    };
  
    /* const ValidateSentinelFilter = () => {
        SetIsReady(false);
        var dates = dateformSentinel.getFieldsValue('daterangeSentinel').daterangeSentinel;
        var evalScript = dateformSentinel.getFieldsValue('evalScript').evalScript;
  
        console.log("here is the dats ###########  "  , dates)
  
        // Generate geometry bounds
        const bounds = geometry[0].map(([lat, lng]) => [lat, lng]);
        bounds.push([geometry[0][0][0], geometry[0][0][1]]); // Close the geometry loop
        const newBounds = [JSON.stringify([bounds])];
  
        var options = {
            id: datasetid,
            datasetPersistentId: persistentId,
            requestOptions: {
              Geometry: newBounds,
  
              Date: dates.map((date) => date.toJSON()),
            },
        };
        SetSentinelOptions(options);
        SetIsReady(true);
  
        searchDataSetBreakdownPerIdWithOptionsAndPost(keycloak.token, datasetid, JSON.stringify(options)).then((response) => {
            SetIsReady(false);
            console.log(response);
            if (JSON.stringify(response.payload.products) === '[]' || JSON.stringify(response.payload.products) === 'null') {
                addAppCustomNotification('Dashboard KG', 'WARNING', 'No dataset for this area!');
                SetIsReady(false);
            } else if (JSON.stringify(response.payload.products) === '{}') {
                addAppCustomNotification(
                    'Dashboard KG',
                    'WARNING',
                    'No dataset for this area! Please try with a different range of date or area!',
                );
                SetIsReady(false);
            } else {
                var productIds = response.payload.products.map((p, key) => {
                    return {
                        label: p.ID,
                        value: p.ID,
                        title: p.ID,
                    };
                });
  
                var downloadOptions = [];
                downloadOptions.push({
                    label: 'True',
                    value: 'True',
                });
  
                downloadOptions.push({
                    label: 'False',
                    value: 'False',
                });
                console.log('Data for testing ', productIds);
                SetAdamProductList(productIds);
                SetAdamDownloadOptions([]);
  
                SetFilteredAdamData(response.payload.products);
            }
        });
    }; */
  
  
  
  
    const onSelectAllOptions = () => {
        SetSelectedMetaInfoFields(adamProductList.map((item) => item.value));
    };
  
    const onDeselectAllOptions = () => {
        SetSelectedMetaInfoFields([]);
    };
  
    const onChangeTreeSelect = (ids) => {
        SetSelectedMetaInfoFields(ids);
    };
    const handleChange = (value) => {
        setSelectedOption(value);
        console.log('handle change here');
        console.log(value);
  
        if (value != '') {
            setPolygon(JSON.parse(value));
        } else {
            setPolygon([]);
            setRemovePolygon(false);
        }
    };
    const deleteAll = (value) => {
        setRemovePolygon(true);
        setSelectedOption(selectedPolygonDropdown[0]); // Reset to the first option
    };
  
    //const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [isAdvancedForCreateWorkflow, setIsAdvancedForCreateWorkflow] = useState(false);
  
    useEffect(() => {
        // Automatically update selectedMetaInfoFields when selectedProducts or selectedOptions change
        console.log("Effect triggered with:", {
            selectedProducts,
            selectedOptions,
            isAdvancedForCreateWorkflow,
            hasBreakdown: !!breakdown,
            hasCascader: !!(breakdown && breakdown.cascader)
        });
        updateSelectedMetaInfoFields();
    }, [selectedProducts, selectedOptions, isAdvancedForCreateWorkflow]);
  
    const handleProductChange = (value) => {
        setSelectedProducts(value);
        setValidationErrors({});
    };
  
    const handleOptionChange = (value, product, category) => {
        // Update selectedOptions scoped by product and category
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [product]: {
                ...prevOptions[product],
                [category]: value,
            },
        }));
  
        // Clear validation errors for this specific product-category combination
        setValidationErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (newErrors[product]?.[category]) {
                delete newErrors[product][category];
            }
            return newErrors;
        });
    };
  
    const handleSelectAll = (product, category, options) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [product]: {
                ...prevOptions[product],
                [category]: options.map((opt) => opt.value),
            },
        }));
    };
  
    const handleUnselectAll = (product, category) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [product]: {
                ...prevOptions[product],
                [category]: [],
            },
        }));
    };
  
    const renderOptions = (product, options) => {
        return options.map((option) => {
            if (option.children) {
                const productOptions = selectedOptions[product] || {};
                const hasError = validationErrors[product]?.[option.value];
                const selectedValues = productOptions[option.value] || [];
  
                return (
                    <div key={option.value} style={{ marginBottom: '1rem' }}>
                        <label style={{ fontWeight: 'bold' }}>{option.label}:</label>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                            <Select
                                mode='multiple'
                                style={{
                                    flex: 1,
                                    borderColor: hasError ? 'red' : undefined,
                                }}
                                placeholder={`Select ${option.label}`}
                                value={selectedValues}
                                onChange={(value) => handleOptionChange(value, product, option.value)}
                            >
                                {option.children.map((child) => (
                                    <Option key={child.value} value={child.value}>
                                        {child.label}
                                    </Option>
                                ))}
                            </Select>
                            <Button
                                type='link'
                                style={{ marginLeft: '1rem' }}
                                onClick={() => handleSelectAll(product, option.value, option.children)}
                            >
                                Select All
                            </Button>
                            <Button type='link' onClick={() => handleUnselectAll(product, option.value)}>
                                Unselect All
                            </Button>
                        </div>
                        {hasError && (
                            <p style={{ color: 'red', marginTop: '0.5rem' }}>Please select at least one option for {option.label}.</p>
                        )}
                        <div style={{ marginLeft: '1rem', marginTop: '1rem' }}>{renderOptions(product, option.children)}</div>
                    </div>
                );
            }
            return null;
        });
    };
  
    const updateSelectedMetaInfoFields = () => {
        const updatedFields = [];
  
        console.log("Updating meta info fields:", {
            selectedProducts,
            isAdvancedForCreateWorkflow,
            breakdownCascaderLength: breakdown?.cascader?.length
        });
        if (!isAdvancedForCreateWorkflow) {
            // If not in advanced mode, automatically select all options for selected products
            selectedProducts.forEach((product) => {
                const productData = breakdown.cascader.find((p) => p.value === product);
                if (productData) {
                    const addFields = (options) => {
                        options.forEach((option) => {
                            if (option.children) {
                                option.children.forEach((child) => {
                                    updatedFields.push([product, option.value, child.value]);
                                });
                                addFields(option.children); // Recursively add children
                            }
                        });
                    };
                    addFields(productData.children);
                }
            });
        } else {
            // If in advanced mode, add only selected options for selected products
            selectedProducts.forEach((product) => {
                const productData = breakdown.cascader.find((p) => p.value === product);
                if (productData) {
                    const addSelectedFields = (options) => {
                        options.forEach((option) => {
                            // Get the selected values for this product and option category
                            const selectedValues = selectedOptions[product]?.[option.value] || [];
                            selectedValues.forEach((value) => {
                                updatedFields.push([product, option.value, value]);
                            });
                            if (option.children) {
                                addSelectedFields(option.children);
                            }
                        });
                    };
                    addSelectedFields(productData.children);
                }
            });
        }
  
        // Update the state of selectedMetaInfoFields
        SetSelectedMetaInfoFields(updatedFields);
    };
  
    return (
        <Modal
            title={
                <Row justify={'space-between'}>
                    <Col span={15}>
                        <Text strong style={{ color: '#418cc0', fontSize: 18 }}>
                            {' '}
                            {dataset.title}{' '}
                        </Text>
                    </Col>
                    <Col span={6} offset={2}>
                        {' '}
                        <>
                            {' '}
                            {dataset.source} |
                            <span>
                                <i style={{ marginLeft: 15 }}>From Adam: </i>
                                {dataset.isfromadam === true && (
                                    <Tag color={'green'} key='0'>
                                        {' '}
                                        YES
                                    </Tag>
                                )}
                                {dataset.isfromadam === false && (
                                    <Tag color={'red'} key='1'>
                                        {' '}
                                        NO
                                    </Tag>
                                )}
                            </span>
                        </>
                    </Col>
                </Row>
            }
            centered
            open={isopen}
            onCancel={onCloseModal}
            onOk={onCloseModal}
            destroyOnClose={true}
            maskClosable={false}
            width={1400}
            footer={[
                <Button type='primary' key='back' onClick={onCloseModal}>
                    Close
                </Button>,
            ]}
        >
            <Divider />
            <Collapse defaultActiveKey={['0']}>
                <Panel header='Description' key='0'>
                    {dataset.description}
                </Panel>
                <Panel header='File Formats' key='2'>
                    {dataset.fileformats}
                </Panel>
                {(dataset.isfromadam || dataset.isfromSentinel || sources.some(item => item.toLowerCase() === "silam")) && (
                    <Panel header={(dataset.isfromadam
                        ? "ADAM"
                        : (sources.some(item => item.toLowerCase() === "silam") ? "Silam" : "Sentinel"))
                        + " Advanced Filter"} key='5'>
  
                        <Row justify={'space-between'}>
                            <Col span={24}>
                                <Card
                                    title={
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            Geometry
                                            <Space wrap>
                                                <Button type='primary' danger icon={<DeleteOutlined />} onClick={deleteAll}>
                                                    Delete All
                                                </Button>
                                                <Select
                                                    style={{
                                                        width: 240,
                                                    }}
                                                    value={selectedOption}
                                                    onChange={handleChange}
                                                    options={selectedPolygonDropdown}
                                                />{' '}
                                            </Space>
                                        </div>
                                    }
                                    bordered={false}
                                >
                                    {(dataset.isfromadam || sources.some(item => item.toLowerCase() === "silam")) && (
                                        <PolygonMap
                                            onGeometryChange={getGeometry}
                                            removePolygons={getRemovePolygon}
                                            deleteAll={removedPolygon}
                                            selectedPolygon={selectedPolygon}
                                            polygonCords={['[[-180,-84],[180,-84],[180,84],[-180,84],[-180,-84]]']}
                                            zoomSize={9}
                                            drawing={true}
                                            toBbox={false}
                                        ></PolygonMap>
                                    )}
                                    {dataset.isfromSentinel && (
                                        <PolygonMap
                                            onGeometryChange={getGeometry}
                                            removePolygons={getRemovePolygon}
                                            selectedPolygon={selectedPolygon}
                                            deleteAll={removedPolygon}
                                            polygonCords={['[[-180,-84],[180,-84],[180,84],[-180,84],[-180,-84]]']}
                                            zoomSize={9}
                                            drawing={true}
                                            toBbox={true}
                                        ></PolygonMap>
                                    )}
                                </Card>
                            </Col>
                            {dataset.isfromSentinel && (
                                <Col span={24}>
                                    <Card title='Dates' bordered={false}>
                                        <span>Pick a Date Range: </span>
                                        <Form form={dateformSentinel}>
                                            <Form.Item name='daterange'>
                                                <RangePicker
                                                    defaultValue={[dayjs('01/01/2024', 'DD/MM/YYYY'), dayjs('15/01/2024', 'DD/MM/YYYY')]}
                                                    format={'DD/MM/YYYY'}
                                                />
                                            </Form.Item>
  
                                            {/* Evaluation Script Marios Ask me to hide it
                      <span>Evaluation Script: </span> */}
                                            <Form.Item name='evalScript' style={{ display: 'none' }}>
                                                <Input.TextArea rows={6} style={{ display: 'none' }} />
                                            </Form.Item>
                                        </Form>
                                    </Card>
                                </Col>
                            )}
                            {(dataset.isfromadam || sources.some(item => item.toLowerCase() === "silam")) && (
                                <Col span={24}>
                                    <Card title='Dates' bordered={false}>
                                        <span>Pick a Date Range: </span>
                                        <Form form={dateform}>
                                            <Form.Item name={'daterange'}>
                                                <RangePicker
                                                    defaultValue={[dayjs('01/01/2024', 'DD/MM/YYYY'), dayjs('15/01/2024', 'DD/MM/YYYY')]}
                                                    format={'DD/MM/YYYY'}
                                                />
                                            </Form.Item>
                                        </Form>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                        <Row justify={'center'}>
                            <Col span={2}>
                                {(dataset.isfromadam ) && (
                                    <Button size={'large'} type={'default'} onClick={ValidateAdamFilter}>
                                        Filter Now!
                                    </Button>
                                )}
                                
                                {/* {dataset.isfromSentinel && (
                                    <Button size={'large'} type={'default'} onClick={ValidateSentinelFilter}>
                                        Filter Now!
                                    </Button>
                                )} */}
                            </Col>
                        </Row>
                    </Panel>
                )}
            </Collapse>
            <br />
            <Card title='Create Workflow'>
                <Spin spinning={isReady} tip='Please wait! We are fetching products in that area from the Knowledge graph...'>
                    <Card>
                        <Form form={form} name='advanced_filter'>
                            <Row>
                                {(dataset.isfromadam) && (
                                    <>
                                        <Col span={15}>
                                            <TreeSelect
                                                allowClear={true}
                                                placeholder='Select Dataset Meta Info'
                                                treeCheckable={true}
                                                style={{
                                                    width: '100%',
                                                }}
                                                showCheckedStrategy={TreeSelect.SHOW_CHILD}
                                                dropdownStyle={{ maxHeight: '400px' }}
                                                onChange={(ids) => onChangeTreeSelect(ids)}
                                                value={selectedMetaInfoFields}
                                                maxTagCount={4}
                                                maxTagPlaceholder={(omittedValues) => `+ ${omittedValues.length} Products ...`}
                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                showSearch={true}
                                                treeData={[
                                                    {
                                                        title:
                                                            selectedMetaInfoFields.length > 0 ? (
                                                                <span
                                                                    onClick={onDeselectAllOptions}
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        color: '#286FBE',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    Unselect all
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    onClick={onSelectAllOptions}
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        color: '#286FBE',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    Select all
                                                                </span>
                                                            ),
                                                        value: 'xxx',
                                                        disableCheckbox: true,
                                                        disabled: true,
                                                    },
                                                    ...adamProductList.map((item) => {
                                                        return { title: item.value, value: item.value };
                                                    }),
                                                ]}
                                            />
                                        </Col>
                                        <Col span={4} offset={1}>
                                            <Form.Item name='Dataset Download Option'>
                                                <Select
                                                    showSearch
                                                    placeholder='Select Download Option'
                                                    style={{ width: '100%' }}
                                                    disabled={!isopen}
                                                    optionFilterProp='children'
                                                    options={adamDownloadOptions}
                                                    onChange={onchangeDownloadOptions}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                                {!(dataset.isfromadam || dataset.isfromSentinel ) && (
                                    <>
                                        <Col span={10}>
                                            <Form.Item name='Dataset Meta Info'>
                                                <label style={{ fontWeight: 'bold' }}>Select: </label>
                                                <Select
                                                    mode='multiple'
                                                    style={{ width: '70%' }}
                                                    placeholder='Select products'
                                                    value={selectedProducts}
                                                    onChange={handleProductChange}
                                                >
                                                    {breakdown.cascader &&
                                                        breakdown.cascader.map((product) => (
                                                            <Option key={product.value} value={product.value}>
                                                                {product.label}
                                                            </Option>
                                                        ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Button
                                                type={isAdvancedForCreateWorkflow ? 'primary' : 'default'}
                                                onClick={() => setIsAdvancedForCreateWorkflow((prev) => !prev)}
                                            >
                                                {isAdvancedForCreateWorkflow ? 'Hide Advanced' : 'Advanced'}
                                            </Button>
                                        </Col>
                                        {!(sources.some(item => item.toLowerCase() === "silam")) && (
  
                                          <Col span={5} offset={1}>
                                              <Form.Item name='Dataset Download Option'>
                                                  <Select
                                                      showSearch
                                                      placeholder='Select Download Option'
                                                      style={{ width: '100%' }}
                                                      disabled={!isopen}
                                                      optionFilterProp='children'
                                                      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                      options={breakdown.download_options}
                                                      onChange={onchangeDownloadOptions}
                                                  />
                                              </Form.Item>
                                          </Col>
                                        )}
                                        
                                    </>
                                )}
  
                                <Col span={3} offset={1}>
                                    {(dataset.isfromadam) && (
                                        <Button type={'primary'} onClick={onGenerateFromAdamPythonScript}>
                                            Generate Script
                                        </Button>
                                    )}
                                    {dataset.isfromSentinel && (
                                        <Button type={'primary'} onClick={onGenerateFromSentinelPythonScript}>
                                            Generate Script
                                        </Button>
                                    )}
                                    {!(dataset.isfromadam || dataset.isfromSentinel) && (
                                        <Button type={'primary'} onClick={onGeneratePythonScript}>
                                            Generate Script
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                            <br />
                        </Form>
                        {isAdvancedForCreateWorkflow && selectedProducts.length > 0 && (
                            <div>
                                {selectedProducts.map((product) => {
                                    const productData = breakdown.cascader.find((p) => p.value === product);
                                    return (
                                        <Card key={product} title={`Selected Product: ${productData?.label}`} style={{ marginBottom: '1.5rem' }}>
                                            {productData && renderOptions(product, productData.children)}
                                        </Card>
                                    );
                                })}
                            </div>
                        )}
                    </Card>
                    <Tabs tabPosition='left' defaultActiveKey='1' items={tabItems} />
                    {generateScript && (
                        <>
                            <Row justify={'center'}>
                                <Col span={2}>
                                    <Button type={'primary'} onClick={onCreateWF}>
                                        Save WF Product
                                    </Button>
                                </Col>
                            </Row>{' '}
                        </>
                    )}
                </Spin>
            </Card>
        </Modal>
    );
  }
  
  const mapStateToProps = (state) => {
    return {
        _products: state._todoProduct,
        productsWF: state.products.items,
    };
  };
  function mapDispatchToProps(dispatch) {
    return {
        addItem: (item) => dispatch(addItem(item)),
    };
  }
  export default connect(mapStateToProps, mapDispatchToProps)(KgDetailedComponent);
  