import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  Select,
  Form,
  Button,
  Modal,
  Spin,
  InputNumber,
  Space,
  Typography,
  Slider,
  Switch,
  Dropdown,
  Menu,
} from 'antd';
import { PlusOutlined, CloseOutlined, DownloadOutlined, EditOutlined, LayoutOutlined } from '@ant-design/icons';
import ReactECharts from 'echarts-for-react';
import { useKeycloak } from '@react-keycloak/web';
import { getBucketFiles } from '../../services/S3BucketService';
import { addAppCustomNotification } from '../../components/NotificationBox';
import { getUserWorkflows } from '../../services/dsl_DataService';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const { Option } = Select;
const { Text } = Typography;

const ChartItemType = 'CHART';

const DraggableChartCard = ({ chart, index, moveChart, renderChart, width, handleWidthChange }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ChartItemType,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveChart(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ChartItemType,
    item: { type: ChartItemType, id: chart.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  // Dropdown menu for width options
  const widthMenu = (
    <Menu onClick={(e) => handleWidthChange(chart.id, e.key)}>
      <Menu.Item key='24'>Full Width</Menu.Item>
      <Menu.Item key='12'>Half Width</Menu.Item>
      <Menu.Item key='8'>One-Third Width</Menu.Item>
    </Menu>
  );

  return (
    <Col span={width || 24} style={{ marginBottom: 16 }}>
      <div
        ref={ref}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: 'move',
        }}
      >
        {renderChart(chart)}
      </div>
    </Col>
  );
};

const DataVisualizationV2 = () => {
  const [bucketFiles, setBucketFiles] = useState([]);
  const [charts, setCharts] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedChartType, setSelectedChartType] = useState(null);
  const [fileColumns, setFileColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState({ x: null, y: null });
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowRange, setRowRange] = useState([0, 100]);
  const [totalRows, setTotalRows] = useState(0);
  const [useAllRows, setUseAllRows] = useState(true);
  const [colorTheme, setColorTheme] = useState('default');
  const [form] = Form.useForm();
  const { keycloak } = useKeycloak();
  const [s3BucketOptions, setS3BucketOptions] = useState([]);
  const chartRefs = useRef({});
  const [editingChartId, setEditingChartId] = useState(null);
  const [chartWidths, setChartWidths] = useState({});

  // Extended chart types
  const chartTypes = [
    { value: 'line', label: 'Line Chart' },
    { value: 'bar', label: 'Bar Chart' },
    { value: 'scatter', label: 'Scatter Plot' },
    { value: 'pie', label: 'Pie Chart' },
    { value: 'area', label: 'Area Chart' },
  ];

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const response = await getUserWorkflows(keycloak.token);
        const buckets = response.map((bucket) => ({
          label: bucket.name,
          value: bucket.id,
        }));
        setS3BucketOptions(buckets);
      } catch (err) {
        addAppCustomNotification('Dashboard Data Visualization', 'CRITICAL', 'Encountered an error!');
        console.error('Error fetching workflows:', err);
      }
    };

    fetchWorkflows();
  }, [keycloak.token]);

  const parseCSV = async (fileUrl) => {
    setLoading(true);
    try {
      const response = await fetch(fileUrl);
      const text = await response.text();
      const lines = text.split('\n').filter((line) => line.trim());
      const headers = lines[0].split(',').map((header) => header.trim());
      const data = lines.slice(1).map((line) => {
        const values = line.split(',');
        return headers.reduce((obj, header, index) => {
          obj[header] = values[index];
          return obj;
        }, {});
      });
      setFileColumns(headers);
      setTotalRows(data.length);
      setRowRange([0, Math.min(100, data.length)]);
      setLoading(false);
      return data;
    } catch (error) {
      console.error('Error parsing CSV:', error);
      setLoading(false);
      return [];
    }
  };

  const handleFileSelect = async (value) => {
    setSelectedFile(value);
    const parsedFile = JSON.parse(value);
    if (parsedFile.url) {
      const data = await parseCSV(parsedFile.url);
      setChartData(data);
    }
  };

  const createChart = () => {
    // Use all rows if selected, otherwise slice the data accordingly.
    const selectedData = useAllRows ? chartData : chartData.slice(rowRange[0], rowRange[1]);
    const parsedFile = JSON.parse(selectedFile);

    if (editingChartId) {
      // Update existing chart
      setCharts((prevCharts) =>
        prevCharts.map((chart) => {
          if (chart.id === editingChartId) {
            return {
              ...chart,
              type: selectedChartType,
              file: parsedFile,
              columns: selectedColumns,
              data: selectedData,
              colorTheme: colorTheme,
              rowRange: useAllRows ? [0, chartData.length] : rowRange,
            };
          }
          return chart;
        }),
      );
      setEditingChartId(null);
    } else {
      // Create new chart
      const newChart = {
        id: Date.now(),
        type: selectedChartType,
        file: parsedFile,
        columns: selectedColumns,
        data: selectedData,
        colorTheme: colorTheme,
        rowRange: useAllRows ? [0, chartData.length] : rowRange,
      };
      setCharts([...charts, newChart]);
    }

    setModalVisible(false);
    resetSelections();
  };

  const resetSelections = () => {
    setSelectedFile(null);
    setSelectedChartType(null);
    setSelectedColumns({ x: null, y: null });
    setUseAllRows(true);
    setRowRange([0, 100]);
    setColorTheme('default');
    setFileColumns([]);
    setChartData([]);
    setTotalRows(0);
  };

  const editChart = (chartId) => {
    const chartToEdit = charts.find((chart) => chart.id === chartId);
    if (chartToEdit) {
      setEditingChartId(chartId);
      setSelectedFile(JSON.stringify(chartToEdit.file));
      setSelectedChartType(chartToEdit.type);
      setSelectedColumns(chartToEdit.columns);
      setUseAllRows(chartToEdit.rowRange[0] === 0 && chartToEdit.rowRange[1] === chartToEdit.data.length);
      setRowRange(chartToEdit.rowRange);
      setColorTheme(chartToEdit.colorTheme);

      // Re-fetch the file data
      handleFileSelect(JSON.stringify(chartToEdit.file));
      setModalVisible(true);
    }
  };

  const downloadChart = (chartId) => {
    const chartRef = chartRefs.current[chartId];
    if (chartRef) {
      const chartInstance = chartRef.getEchartsInstance();
      const dataURL = chartInstance.getDataURL({
        type: 'png',
        pixelRatio: 2,
        backgroundColor: '#fff',
      });

      const link = document.createElement('a');
      link.href = dataURL;
      link.download = `chart-${chartId}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const removeChart = (chartId) => {
    setCharts(charts.filter((chart) => chart.id !== chartId));
    setChartWidths((prev) => {
      const updated = { ...prev };
      delete updated[chartId];
      return updated;
    });
  };

  const getEChartsOption = (chart) => {
    // Prepare the data for charts that use two axes.
    const formattedData = chart.data.map((row) => [row[chart.columns.x], parseFloat(row[chart.columns.y]) || 0]);

    // Base option for line, bar, scatter, area
    const baseOption = {
      title: {
        text:
          chart.type === 'pie'
            ? `${chart.file.id}: ${chart.columns.y} Distribution`
            : `${chart.file.id}: ${chart.columns.y} vs ${chart.columns.x}`,
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        name: chart.columns.x,
      },
      yAxis: {
        type: 'value',
        name: chart.columns.y,
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '10%',
        containLabel: true,
      },
    };

    switch (chart.type) {
      case 'line':
        return {
          ...baseOption,
          series: [
            {
              data: formattedData,
              type: 'line',
              smooth: true,
            },
          ],
        };
      case 'bar':
        return {
          ...baseOption,
          series: [
            {
              data: formattedData,
              type: 'bar',
            },
          ],
        };
      case 'scatter':
        return {
          ...baseOption,
          series: [
            {
              data: formattedData,
              type: 'scatter',
            },
          ],
        };
      case 'pie': {
        // For pie charts, transform the data so that the first column is used as the name and the second as the value.
        const pieData = formattedData.map((item) => ({
          name: item[0],
          value: item[1],
        }));
        return {
          title: {
            text: `${chart.file.id}: ${chart.columns.y} Distribution`,
            left: 'center',
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)',
          },
          legend: {
            orient: 'vertical',
            left: 'left',
            data: pieData.map((item) => item.name),
          },
          series: [
            {
              name: chart.columns.y,
              type: 'pie',
              radius: '55%',
              center: ['50%', '60%'],
              data: pieData,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ],
        };
      }
      case 'area':
        return {
          ...baseOption,
          series: [
            {
              data: formattedData,
              type: 'line',
              smooth: true,
              areaStyle: {},
            },
          ],
        };
      default:
        return baseOption;
    }
  };

  const renderChart = (chart) => {
    // Create a title that includes the chart type and file name.
    const chartTitle = `${chart.type.charAt(0).toUpperCase() + chart.type.slice(1)} Chart - ${chart.file.id}`;
    const subtitle = `Axes: ${chart.columns.x} vs ${chart.columns.y} | Rows: ${chart.rowRange[0]} to ${
      chart.rowRange[1]
    } (${chart.rowRange[1] - chart.rowRange[0]} rows)`;

    return (
      <Card
        key={chart.id}
        style={{ marginBottom: 16, height: '100%' }}
        extra={
          <Space>
            <Dropdown overlay={layoutMenu(chart.id)} placement='bottomRight'>
              <Button type='text' icon={<LayoutOutlined />} title='Change Layout' />
            </Dropdown>
            <Button type='text' icon={<EditOutlined />} title='Edit Chart' onClick={() => editChart(chart.id)} />
            <Button
              type='text'
              icon={<DownloadOutlined />}
              title='Download Chart'
              onClick={() => downloadChart(chart.id)}
            />
            <Button type='text' icon={<CloseOutlined />} onClick={() => removeChart(chart.id)} title='Remove Chart' />
          </Space>
        }
        title={
          <div>
            <div>{chartTitle}</div>
            <Text type='secondary' style={{ fontSize: '12px' }}>
              {subtitle}
            </Text>
          </div>
        }
      >
        <ReactECharts
          ref={(ref) => (chartRefs.current[chart.id] = ref)}
          option={getEChartsOption(chart)}
          style={{ height: 300 }}
          theme={chart.colorTheme === 'default' ? undefined : chart.colorTheme}
        />
      </Card>
    );
  };

  const loadBucketFiles = async (bucketId) => {
    try {
      const files = await getBucketFiles(keycloak.token, bucketId);
      const filteredFiles = files
        .filter((file) => ['.csv'].some((ext) => file.name.toLowerCase().endsWith(ext)))
        .map((file) => ({
          label: file.name,
          value: JSON.stringify({
            id: file.name,
            type: file.name.split('.').pop(),
            url: file.presignedUrl,
          }),
        }));
      setBucketFiles(filteredFiles);
    } catch (err) {
      console.error('Error loading bucket files:', err);
    }
  };

  const handleBucketChange = (value) => {
    loadBucketFiles(value);
  };

  const moveChart = (dragIndex, hoverIndex) => {
    const draggedChart = charts[dragIndex];
    const updatedCharts = [...charts];
    updatedCharts.splice(dragIndex, 1);
    updatedCharts.splice(hoverIndex, 0, draggedChart);
    setCharts(updatedCharts);
  };

  const handleWidthChange = (chartId, width) => {
    setChartWidths((prev) => ({
      ...prev,
      [chartId]: parseInt(width),
    }));
  };

  const layoutMenu = (chartId) => (
    <Menu onClick={({ key }) => handleWidthChange(chartId, key)}>
      <Menu.Item key='24'>Full Width</Menu.Item>
      <Menu.Item key='12'>Half Width</Menu.Item>
      <Menu.Item key='8'>One-Third Width</Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Form form={form}>
        <Card>
          <Row>
            <Col span={14}>
              <Form.Item label='Workflow' required>
                <Select
                  showSearch
                  placeholder='Select Workflow'
                  optionFilterProp='children'
                  onChange={handleBucketChange}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={s3BucketOptions}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Button type='primary' icon={<PlusOutlined />} onClick={() => setModalVisible(true)}>
              Add Chart
            </Button>
          </Row>
        </Card>
      </Form>

      <Modal
        title={editingChartId ? 'Edit Chart' : 'Create New Chart'}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingChartId(null);
          resetSelections();
        }}
        footer={[
          <Button
            key='cancel'
            onClick={() => {
              setModalVisible(false);
              setEditingChartId(null);
              resetSelections();
            }}
          >
            Cancel
          </Button>,
          <Button key='submit' type='primary' onClick={createChart} disabled={!selectedColumns.x || !selectedColumns.y}>
            {editingChartId ? 'Update Chart' : 'Create Chart'}
          </Button>,
        ]}
        width={700}
        centered
        destroyOnClose
        bodyStyle={{ padding: '24px' }}
      >
        <Spin spinning={loading}>
          <Form layout='vertical'>
            {/* Select File */}
            <Form.Item label='Select File' required>
              <Select
                placeholder='Choose a file'
                value={selectedFile}
                onChange={handleFileSelect}
                style={{ width: '100%' }}
              >
                {bucketFiles.map((file) => (
                  <Option key={file.value} value={file.value}>
                    {file.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {selectedFile && (
              <>
                {/* Data Range */}
                <Form.Item label='Data Range'>
                  <Switch
                    checked={useAllRows}
                    onChange={setUseAllRows}
                    checkedChildren='All Rows'
                    unCheckedChildren='Custom Range'
                    style={{ marginBottom: 16 }}
                  />

                  {!useAllRows && totalRows > 0 && (
                    <div>
                      <Slider
                        range
                        marks={{ 0: '0', [totalRows]: totalRows.toString() }}
                        min={0}
                        max={totalRows}
                        value={rowRange}
                        onChange={setRowRange}
                        style={{ marginBottom: 16 }}
                      />
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label='Start Row'>
                            <InputNumber
                              min={0}
                              max={totalRows - 1}
                              value={rowRange[0]}
                              onChange={(val) => setRowRange([val, rowRange[1]])}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label='End Row'>
                            <InputNumber
                              min={rowRange[0] + 1}
                              max={totalRows}
                              value={rowRange[1]}
                              onChange={(val) => setRowRange([rowRange[0], val])}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Text type='secondary'>
                        Selected {rowRange[1] - rowRange[0]} rows out of {totalRows} total rows.
                      </Text>
                    </div>
                  )}
                </Form.Item>

                {/* Chart Type */}
                <Form.Item label='Chart Type' required>
                  <Select
                    placeholder='Choose a chart type'
                    value={selectedChartType}
                    onChange={setSelectedChartType}
                    style={{ width: '100%' }}
                  >
                    {chartTypes.map((type) => (
                      <Option key={type.value} value={type.value}>
                        {type.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}

            {selectedChartType && (
              <>
                {/* Axis Selection */}
                <Form.Item label={selectedChartType === 'pie' ? 'Label Column' : 'X-Axis Column'} required>
                  <Select
                    placeholder='Select column for X-Axis / Label'
                    value={selectedColumns.x}
                    onChange={(value) => setSelectedColumns((prev) => ({ ...prev, x: value }))}
                    style={{ width: '100%' }}
                  >
                    {fileColumns.map((column) => (
                      <Option key={column} value={column}>
                        {column}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label={selectedChartType === 'pie' ? 'Value Column' : 'Y-Axis Column'} required>
                  <Select
                    placeholder='Select column for Y-Axis / Value'
                    value={selectedColumns.y}
                    onChange={(value) => setSelectedColumns((prev) => ({ ...prev, y: value }))}
                    style={{ width: '100%' }}
                  >
                    {fileColumns.map((column) => (
                      <Option key={column} value={column}>
                        {column}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}
          </Form>
        </Spin>
      </Modal>

      <DndProvider backend={HTML5Backend}>
        {charts.length === 0 ? (
          <Card>
            <Text type='secondary'>
              No charts created yet. Use the "Add Chart" button to create a new visualization.
            </Text>
          </Card>
        ) : (
          <Row gutter={16}>
            {charts.map((chart, index) => (
              <DraggableChartCard
                key={chart.id}
                chart={chart}
                index={index}
                moveChart={moveChart}
                renderChart={renderChart}
                width={chartWidths[chart.id] || 24}
                handleWidthChange={handleWidthChange}
              />
            ))}
          </Row>
        )}
      </DndProvider>
    </div>
  );
};

export default DataVisualizationV2;
