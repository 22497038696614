import React, { useState, useEffect, useLayoutEffect } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import DrawControl from "./drawControl";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "./polygonMap.css";


export default function PolygonMap({ onGeometryChange, selectedPolygon, polygonCords, zoomSize, drawing, removePolygons, deleteAll, toBbox }) {
    const [editMode, toggleEditMode] = useState(false);
    const [polygon, setPolygon] = useState({});
    const [cords, setCords] = useState([]);
    const [geometry, setGeometry] = useState([]);
    const [center, setCenter] = useState([54.5260, 15.2551]);
    const [selectPolygon, setSelectPolygon] = useState({});
    const [defaultGeoJSON, setDefaultGeoJSON] = useState([]);

    function calculatePolygonCenter(coordinates) {
        const latitudes = coordinates.map(coord => coord[1]);
        const longitudes = coordinates.map(coord => coord[0]);
        const avgLatitude = latitudes.reduce((sum, lat) => sum + lat, 0) / latitudes.length;
        const avgLongitude = longitudes.reduce((sum, lon) => sum + lon, 0) / longitudes.length;
        return [avgLongitude, avgLatitude];  
    }


    // Function to convert polygon coordinates to a bounding box
    function convertToBbox(coordinates) {
        const coords = coordinates[0];
        const lats = coords.map(coord => coord[1]);
        const lngs = coords.map(coord => coord[0]);

        const minLat = Math.min(...lats);
        const maxLat = Math.max(...lats);
        const minLng = Math.min(...lngs);
        const maxLng = Math.max(...lngs);

        return [minLng, minLat, maxLng, maxLat];
    }


  const sendData = (childData) => {
    onGeometryChange(childData);
  };
  const sendDeleteData = (childData) => {
    removePolygons(childData);
  };
  const getCords = (childData) => {
    setCords(childData);
    sendData(childData);
  };

  useEffect(() => {
    if (deleteAll == true) {
      setDefaultGeoJSON([]);
      sendDeleteData(false);
    } 
  }, [selectedPolygon, defaultGeoJSON, setDefaultGeoJSON, removePolygons, deleteAll]);

  useLayoutEffect(() => {
    if (selectedPolygon.length > 0) {
      setCords(selectedPolygon);
      sendData(selectedPolygon);
      const resultArray = (selectedPolygon[0]).map((row) => [row[1], row[0]]);
      const tempgeoJson = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            resultArray.map((row) => [row[1], row[0]])
          ]
        }
      };
      setDefaultGeoJSON([...defaultGeoJSON, tempgeoJson]);
    }
    if (polygonCords !== undefined) {
      const resultArray = JSON.parse(polygonCords[0]).map((row) => [row[1], row[0]]);
      const tempgeoJson = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            resultArray.map((row) => [row[1], row[0]])
          ]
        }
      };
      setCenter(calculatePolygonCenter(resultArray));
      setPolygon(tempgeoJson);
      setGeometry(resultArray);
    }
  }, [polygonCords, selectedPolygon]);

  return (
    <div className="PolygonMap">
      <MapContainer
        style={{ width: "100%", height: "750px" }}
        key={`shouldRemount_${editMode}`}
        className="map"
        center={center}
        zoom={zoomSize}
      >
        {!drawing && (
          <GeoJSON data={polygon} />
        )}
        {drawing && (
          <DrawControl onCordsChange={getCords} polygon={polygon} />
        )}
        {defaultGeoJSON.map((feature, index) => (
          <GeoJSON key={index} data={feature} />
        ))}
        <TileLayer
          noWrap={true}
          attribution='&copy; <a href="http://osm.org/copyright">EO4EU</a> Consortium'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <TileLayer
          noWrap={true}
          url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
          maxZoom={20}
          subdomains={['mt1', 'mt2', 'mt3']}
        />
      </MapContainer>
    </div>
  );
}
